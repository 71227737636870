export default {
  siteTitle: 'Get bonus',
  blogerName: 'PICHU',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@PichuSlots'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/pichuslots/'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irrs.com/c0476a0d0',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-blrs.com/c811e87f8',
      gameTitle: 'Savage Buffalo Spirit Megaways',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/c7759eaaf',
      gameTitle: 'JET AIR (BGaming)',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs12.com/c2801e283',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/cf6254718',
      gameTitle: 'Izzi Art (BGaming)', // имя игры изменено. копировать внимательно!
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c085d7e45',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c9dec9f12',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c47bed2f3',
      gameTitle: 'Starda Queen (BGaming)', // имя игры изменено. копировать внимательно!
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/ca1f9787a',
      gameTitle: 'Legzo Punk (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>PICHU</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'PICHU',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
